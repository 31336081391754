import React from 'react';
import { Link } from 'react-router-dom';

interface FolderPathProps {
  path: string;
};

const FolderPath: React.FC<FolderPathProps> = (props) => {
  const pathParts = props.path.split('/').filter((p) => p.length > 0);

  return (
    <div className='my-auto text-lg pl-2'>
      <Link to={'?path=%2F'}><i className='bi bi-house'></i> Home</Link>
      {pathParts.map((part, idx, parts) => {
        const path = ['', ...parts.slice(0, idx + 1)].join('/');

        return (
          <React.Fragment key={idx}>
            <i className='bi bi-caret-right-fill'></i> <Link to={`?path=${path}`}><i className='bi bi-folder'></i> {part}</Link>&nbsp;
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FolderPath;
