import { z } from 'zod';

export const WDApiCredentialSchema = z.object({
  clientId: z.string().uuid(),
  name: z.string(),
  portalRole: z.string(),
  lastActivity: z.string().optional().nullable()
});

type WDApiCredential = z.infer<typeof WDApiCredentialSchema>;

export default WDApiCredential;
