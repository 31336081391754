import React from 'react';

import { useQuery } from '@tanstack/react-query';

import { useAccountContext } from '../../contexts/AccountContext';
import { useAPI } from '../../libs/libAPI';
import { humanFileSize } from '../../libs/libString';
import { WDFileStatsSchema } from '../../types/WDFileStats';

const FileStats: React.FC = () => {
  const api = useAPI();
  const accountContext = useAccountContext();

  const statsQuery = useQuery(['files', accountContext.accountId, 'stats'], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/files/stats`, {
      method: 'GET'
    });

    const stats = await WDFileStatsSchema.parseAsync(await response.json());

    return stats;
  });

  if (statsQuery.data == null) {
    return <></>;
  }

  return <>
    <div className='pt-1 px-3'>
      <div>
        Used: {humanFileSize(statsQuery.data.totalUsed)} / {humanFileSize(statsQuery.data.totalSize)} ({Math.round(((statsQuery.data.totalUsed / statsQuery.data.totalSize) * 1000)) / 10}%)
      </div>
      <div className='h-6 w-full border'>
        <div style={{ width: `${(statsQuery.data.totalUsed / statsQuery.data.totalSize) * 100}%` }} className='h-6 bg-primary'></div>
      </div>
    </div>
  </>;
};

export default FileStats;
