import React from 'react';

import { useFilesApi } from '../../../api';
import Button from '../../../components/Library/Button';
import { humanFileSize } from '../../../libs/libString';
import FilePreviewRenderProps from '../FilePreviewRenderProps';

const DownloadFileRenderer: React.FC<FilePreviewRenderProps> = (props) => {
  const filesApi = useFilesApi();


  const download = (): void => {
    void (async () => {

      const urlResponse = await (async () => {
        if (props.version == null) {
          return await filesApi.get_file_content_url({
            params: {
              file_id: props.file.id
            },
            queries: {
              download: true
            }
          });
        } else {
          return await filesApi.get_file_version_content_url({
            params: {
              file_id: props.file.id,
              version_id: props.version.id
            },
            queries: {
              download: true
            }
          });
        }
      })();

      let a = document.createElement('a');
      a.setAttribute('style', 'display:none');
      a.href = urlResponse.url;
      a.download = props.file.name;
      document.body.append(a);
      a.click();
      a.remove();
    })();
  }

  return <>
    <div className='w-full flex flex-col'>
      <div className='mx-auto my-4 text-xl'>We can not preview this file please download it to open.</div>
      <Button variant='primary' className='mx-auto mb-2' onClick={download}>Download</Button>
      <div className='font-bold mx-auto mb-6'>{props.file.mimeType} - {humanFileSize(props.file.size as number)}</div>
    </div>
  </>;
};

export default DownloadFileRenderer;
