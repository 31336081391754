import React from 'react';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {

};

const Label = React.forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const { className, children, ...otherProps } = props;
  return <label className={`form-label inline-block mb-2 text-foreground ${className ?? ''}`} {...otherProps} ref={ref}>{children}</label>;
});

Label.displayName = 'Label';

export default Label;
