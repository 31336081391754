import React from 'react';

import FilePreviewRenderProps from './FilePreviewRenderProps';
import IframeRenderer from './Renderers/IframeRenderer';
import ImageRenderer from './Renderers/ImageRenderer';

interface FilePreviewRenderer {
  component: React.FC<FilePreviewRenderProps>;
  fileTypes: string[];
}

const RENDERERS: FilePreviewRenderer[] = [
  {
    component: IframeRenderer,
    fileTypes: [
      'application/pdf'
    ]
  },
  {
    component: ImageRenderer,
    fileTypes: [
      'image/png'
    ]
  }
];

export default RENDERERS;
