import { z } from 'zod';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useAPI } from './libAPI';

const useApiQuery = <T>(queryKey: string[], queryPath: string, querySchema: z.AnyZodObject | any): UseQueryResult<T> => {
  const api = useAPI();

  const query = useQuery(queryKey, async () => {
    const response = await api.fetch(queryPath, { method: 'GET' });

    const data: T = await querySchema.parseAsync(await response.json());

    return data;
  });

  return query;
};

export default useApiQuery;
