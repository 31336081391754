import React from 'react';

import CenterLoadingSpinner from '../../../components/LoadingSpinner/CenterLoadingSpinner';
import FilePreviewRenderProps from '../FilePreviewRenderProps';
import useFileContentBlobUrl from '../useFileContent';

const ImageRenderer: React.FC<FilePreviewRenderProps> = (props) => {
  const fileUrl = useFileContentBlobUrl(props.file.id, props.version?.id);

  if (fileUrl == null) {
    return <CenterLoadingSpinner />;
  }

  return <img className='max-w-full max-h-[80vh]' src={fileUrl} />;
}

export default ImageRenderer;