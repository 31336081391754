import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { File, useFilesApi } from '../../api';

const useFileQuery = (fileId: string): UseQueryResult<File> => {
  const filesApi = useFilesApi();

  const query = useQuery(['file', fileId], async () => {
    const file = await filesApi.get_file_info({
      params: {
        file_id: fileId
      }
    });

    return file;
  }, {

  });

  return query;
};

export default useFileQuery;
