import { useEffect, useState } from 'react';

import { useAPI } from '../../libs/libAPI';

const useFileContentBlobUrl = (fileId: string, versionId?: string): string | null => {
  const api = useAPI();
  const [blobUrl, setBlobUrl] = useState<string|null>(null);

  const fetchContent = async (): Promise<void> => {
    const url = versionId == null ? `${fileId}/content` : `${fileId}/versions/${versionId}/content`;
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/files/${url}`, {
      method: 'GET'
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      if (blobUrl != null) {
        URL.revokeObjectURL(blobUrl);
      }
      setBlobUrl(url);
    }
  }

  useEffect(() => {
    void fetchContent();
    return () => {
      if (blobUrl != null) {
        URL.revokeObjectURL(blobUrl);
      }
    }
  }, [fileId, versionId])

  return blobUrl;
};

export default useFileContentBlobUrl;