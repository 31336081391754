import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Input from '../../components/Library/Input';
import Label from '../../components/Library/Label';
import { Modal, ModalBody, ModalHeader } from '../../components/Library/Modal';
import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { humanFileSize } from '../../libs/libString';
import useFileQuery from './useFileQuery';

interface FilePropertiesProps {
  fileId: string;
}

const FileProperties: React.FC<FilePropertiesProps> = (props) => {
  const [search, setSearch] = useSearchParams();

  const { data } = useFileQuery(props.fileId);

  const close = (): void => {
    setSearch((prev) => {
      prev.delete('fileProperties');
      return prev;
    });
  };

  return <>
    <Modal show={true} closeModal={close}>
      <ModalHeader closeModal={close}>{data?.name ?? 'Loading...'}</ModalHeader>
      <ModalBody>
        {data == null
          ? <>
            <CenterLoadingSpinner />
          </>
          : <>
            <div>
              <table>
                <tr>
                  <th className='text-right'>Latest Version Type:</th>
                  <td>{data.mimeType}</td>
                </tr>
                <tr>
                  <th className='text-right'>Latest Version Size:</th>
                  <td>{humanFileSize(data.size as number)}</td>
                </tr>
                <tr>
                  <th className='text-right'>Type:</th>
                  <td>{data.mimeType}</td>
                </tr>
              </table>
            </div>
          </>}
      </ModalBody>
    </Modal>
  </>;
};

export default FileProperties;
