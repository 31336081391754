import { useMemo } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { DirectoryListing, File, Folder, useFilesApi } from '../../api';
import { useAccountContext } from '../../contexts/AccountContext';
import { useAPI } from '../../libs/libAPI';

export const useFoldersMemo = (listing: DirectoryListing): Folder[] => {
  const folders = useMemo(() => {
    return listing.filter(i => !Array.isArray(i) && i.type === 'folder');
  }, [listing]);

  return folders as Folder[];
};

export const useFilesMemo = (listing: DirectoryListing): File[] => {
  const files = useMemo(() => {
    return listing.filter(i => !Array.isArray(i) && i.type === 'file');
  }, [listing]);

  return files as File[];
};

const useFilesQuery = (path: string): UseQueryResult<DirectoryListing> => {
  const filesApi = useFilesApi(); 
  const accountContext = useAccountContext();

  const query = useQuery(['files', accountContext.accountId, path], async () => {
    const listing = await filesApi.get_directory_listing_by_path({queries:{path}});

    return listing
  }, {
    cacheTime: 10 * 60 * 1000,
    staleTime: 60 * 1000
  });

  return query;
};

export default useFilesQuery;
