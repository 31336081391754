import { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useFilesApi } from '../../api';
import Button from '../../components/Library/Button';
import Input from '../../components/Library/Input';
import Label from '../../components/Library/Label';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../components/Library/Modal';
import Select from '../../components/Library/Select';
import LoadingSpinner from '../../components/LoadingSpinner';
import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { useAccountContext } from '../../contexts/AccountContext';
import { useUserContext } from '../../contexts/UserContext';
import { useAPI } from '../../libs/libAPI';
import { WDFileSchema } from '../../types/WDFile';
import { WDFileCategorySchema } from '../../types/WDFileCategory';

interface CreateFileModalProps {
  closeModal: () => void;
}

interface PathOptionValues {
  [key: string]: string;
}

const CreateFileModal: React.FC<CreateFileModalProps> = (props) => {
  const api = useAPI();
  const filesApi = useFilesApi();
  const userContext = useUserContext();
  const accountContext = useAccountContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showOverwritePrompt, setShowOverwritePrompt] = useState(false);

  const [category, setCategory] = useState<string | undefined>(undefined);
  const [pathOptions, setPathOptions] = useState<PathOptionValues>({});
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');
  const [upsert, setUpsert] = useState(false);

  const categoriesQuery = useQuery(['file-categories', accountContext.accountId], async () => {
    const categories = await filesApi.get_file_categories();

    return categories;
  });

  const selectedCategory = useMemo(() => {
    const cat = categoriesQuery.data?.find((c) => c.id === category);
    return cat;
  }, [categoriesQuery, category]);

  const calculatedPath = useMemo(() => {
    if (selectedCategory == null) {
      return '';
    }
    let path = selectedCategory.path;
    Object.keys(pathOptions).forEach((k) => {
      path = path.replaceAll(`\${${k}}`, pathOptions[k]);
    });
    return `${path}/${fileName}`;
  }, [selectedCategory, pathOptions, fileName]);

  if (categoriesQuery.data == null) {
    return <CenterLoadingSpinner />;
  }

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const f = event.target.files![0];
    setFile(f);
    setFileName(f.name);
  };

  const uploadFile = (): void => {
    void (async () => {
      if (category == null) {
        return;
      }

      setIsUploading(true);
      const fileData = {
        category,
        name: fileName,
        pathMetadata: pathOptions,
        upsert
      };

      try {
        const fileResponse = await filesApi.create_new_file(fileData);

        const uploadResponse = await filesApi.create_new_file_version(file!, {
          params: {
            file_id: fileResponse.id,
          },
          headers: {
            'Content-Type': file!.type,
          },
          onUploadProgress: (event) => {
            if (event.lengthComputable) {
              setUploadProgress(event.loaded / event.total!);
            }
          }
        });

        console.log(uploadResponse);

      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.status === 400) {
            setShowOverwritePrompt(true);
          }
        }
        console.error(error);
        return;
        //setShowOverwritePrompt(true);
      }

      // const apiToken = await userContext.getApiToken();

      // await new Promise<void>((resolve) => {
      //   const xhr = new XMLHttpRequest();

      //   xhr.upload.addEventListener('progress', (event) => {
      //     if (event.lengthComputable) {
      //       setUploadProgress(event.loaded / event.total);
      //     }
      //   });

      //   xhr.addEventListener('loadend', () => {
      //     resolve();
      //   });

      //   xhr.open('POST', `${import.meta.env.VITE_API_URI}/v1/files/${fileInfo.id_str}/versions`, true);
      //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      //   xhr.setRequestHeader('Content-Type', file!.type);
      //   xhr.setRequestHeader('Authorization', `Bearer ${apiToken}`);
      //   xhr.send(file);
      // });

      props.closeModal();

      const basePath = calculatedPath.split('/').slice(0, -1).join('/');
      console.log(basePath);
      void queryClient.refetchQueries(['files', accountContext.accountId, basePath]);
      navigate(`..?path=${basePath}`);
      // TODO reset cache
    })();
  };

  const overwrite = (): void => {
    setUpsert(true);
    setShowOverwritePrompt(false);
    uploadFile();
  };

  if (showOverwritePrompt) {
    return <>
      <ModalBody>
        <p>That file already exists. Would you like to overwrite?</p>
        <small>We keep up to 10 versions with a maximum size for all versions totaling 1 GB. We will always keep 2 versions.</small>
      </ModalBody>
      <ModalFooter>
        <Button variant='primary' onClick={overwrite}>Overwrite</Button>
      </ModalFooter>
    </>;
  }

  if (isUploading) {
    return <>
      <ModalBody>
        <div>
          <div className='w-full text-center mb-2'>
            Uploading: {Math.round(uploadProgress * 100)}%
          </div>
          <div className='bg-white w-full h-10 p-0'>
            <div style={{ width: `${uploadProgress * 100}%` }} className='m-0 p-0 bg-primary h-10'></div>
          </div>
        </div>
      </ModalBody>
    </>;
  }

  return <>
    <ModalBody>
      <div>
        <Label htmlFor='category'>Category</Label>
        <Select id='category' className='w-full' value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value={undefined}>Select a file category</option>
          {categoriesQuery.data.map((c) => (
            <option key={c.id} value={c.id}>{c.name}</option>
          ))}
        </Select>
      </div>
      <div>
        <Label htmlFor='file'>File</Label>
        <Input id='file' type='file' onChange={onFileSelected} />
      </div>
      <div>
        <Label htmlFor='fileName'>File Name</Label>
        <Input id='fileName' value={fileName} onChange={(e) => setFileName(e.target.value)} />
      </div>
      {(selectedCategory?.pathOptions.length ?? 0) > 0 && <>

      </>}
      <div>
        <Label htmlFor='path'>Path</Label>
        <Input id='path' readOnly value={calculatedPath} />
      </div>
    </ModalBody>
    <ModalFooter>
        <Button variant='primary' onClick={uploadFile}>Upload</Button>
    </ModalFooter>
  </>;
};

const CreateFile: React.FC = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const close = (): void => {
    const path = search.get('path') ?? '/';
    navigate(`..?path=${path}`);
  };

  return <>
    <Modal show={true} closeModal={close}>
      <ModalHeader closeModal={close}>Upload File</ModalHeader>
      <CreateFileModal closeModal={close} />
    </Modal>
  </>;
};

export default CreateFile;
