import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFilesApi } from '../../api';
import { Modal, ModalBody, ModalHeader, ModalSize } from '../../components/Library/Modal';
import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import RENDERERS from './FilePreviewRenderers';
import DownloadFileRenderer from './Renderers/DownloadFileRenderer';
import useFileQuery from './useFileQuery';

interface FilePreviewContextData {
  setTitle: (title: string) => void;
}

const FilePreviewContext = createContext<FilePreviewContextData>({
  setTitle: () => {}
});
FilePreviewContext.displayName = 'FilePreviewContext';

interface FilePreviewProps {
  fileId: string;
  versionId?: string;
}

const FilePreviewBody: React.FC<FilePreviewProps> = (props) => {
  const filesApi = useFilesApi();
  const { data } = useFileQuery(props.fileId);
  const ctx = useContext(FilePreviewContext)

  useEffect(() => {
    if (data != null) {
      ctx.setTitle(data.name);
    }
  }, [data])

  if (data == null) {
    return <CenterLoadingSpinner />;
  }

  const mimeType = (typeof data.mimeType) == 'string' ? data.mimeType as string : '';

  const RenderComponent = RENDERERS.find((r) => r.fileTypes.includes(mimeType))?.component ?? DownloadFileRenderer;

  const download = (): void => {
    void (async () => {

      const urlResponse = await (async () => {
        if (props.versionId == null) {
          return await filesApi.get_file_content_url({
            params: {
              file_id: props.fileId
            },
            queries: {
              download: true
            }
          });
        } else {
          return await filesApi.get_file_version_content_url({
            params: {
              file_id: props.fileId,
              version_id: props.versionId
            },
            queries: {
              download: true
            }
          });
        }
      })();

      let a = document.createElement('a');
      a.setAttribute('style', 'display:none');
      a.href = urlResponse.url;
      a.download = data.name;
      document.body.append(a);
      a.click();
      a.remove();
    })();
  }

  return <>
    <div className='w-full bg-background3 h-8 flex'>
      <div className='ml-auto'></div>
      <button className='bg-background3 px-2 py-1' onClick={download}><i className="bi bi-download"></i> Download</button>
    </div>
    <RenderComponent file={data} />
  </>;
};

const FilePreview: React.FC<FilePreviewProps> = (props) => {
  const [title, setTitle] = useState<string>();
  const [search, setSearch] = useSearchParams();

  const close = (): void => {
    setSearch((prev) => {
      prev.delete('view');
      prev.delete('version');
      return prev;
    });
  };

  const buttons = <>
  </>;

  const data: FilePreviewContextData = {
    setTitle
  };

  return <>
    <Modal show={true} modalSize='Large' closeModal={close}>
      <ModalHeader closeModal={close} optionButtons={buttons}>{title ?? 'Loading...'}</ModalHeader>
      <ModalBody className='m-0 p-0'>
        <FilePreviewContext.Provider value={data}>
        <FilePreviewBody {...props} />
        </FilePreviewContext.Provider>
      </ModalBody>
    </Modal>
  </>;
};

export default FilePreview;
