import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Point } from './ContextMenuProps';
import FileContextMenu from './FileContextMenu';
import FolderContextMenu from './FolderContextMenu';
import { getFileExtension } from './getFileExtension';
import { useFilesMemo, useFoldersMemo } from './useFilesQuery';
import { ViewModeProps } from './ViewModeProps';

interface ChildProps {
  children: React.ReactNode;
}

interface IconProps extends ChildProps {
  onClick?: () => void;
  onDoubleClick?: () => void;
  onRightClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isSelected: boolean;
}

const Icon: React.FC<IconProps> = (props) => {
  const handleRightClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault();
    if (props.onRightClick != null) {
      props.onRightClick(event);
    }
  };

  return (
    <div className={`flex flex-col flex-flex-wrap min-w-[7rem] w-28 h-28 m-2 p-2 overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer ${props.isSelected ? 'border border-primary bg-primary-lighter' : ''}`} onClick={props.onClick} onDoubleClick={props.onDoubleClick} onContextMenu={handleRightClick}>
      {props.children}
    </div>
  );
};

const IconIcon: React.FC<ChildProps> = (props) => {
  return (
    <div className='text-6xl mx-auto'>
      {props.children}
    </div>
  );
};

const IconLabel: React.FC<ChildProps> = (props) => {
  return (
    <div className='text-center text-sm mt-2 bg-background'>
      {props.children}
    </div>
  );
};

enum ShownContextMenu {
  None = 'none',
  Folder = 'folder',
  File = 'file'
}

const IconView: React.FC<ViewModeProps> = (props) => {
  const [search, setSearch] = useSearchParams();
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
  const [contextMenuLocation, setContextMenuLocation] = useState<Point | null>(null);
  const [shownContextMenu, setShownContextMenu] = useState<ShownContextMenu>(ShownContextMenu.None);

  useEffect(() => {
    setSelectedIcon(null);
  }, [props.path]);

  const folders = useFoldersMemo(props.data);
  const files = useFilesMemo(props.data);

  const setCurrentPath = (path: string): void => {
    console.log(path);
    props.setCurrentPath(path);
  };

  const clearRightClick = () => {
    setShownContextMenu(ShownContextMenu.None);
  };

  const clearClick = () => {
    clearRightClick();
    setSelectedIcon(null);
  };

  const openFile = (fileId: string) => {
    return () => {
      setSearch((prev) => {
        prev.set('view', fileId);
        return prev;
      });
    };
  };

  const rightClickHandler = (contextMenu: ShownContextMenu, path: string) => {
    return (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const pathParts = path.split('/');
      const icon = pathParts[pathParts.length - 1];
      setSelectedIcon(icon);
      setShownContextMenu(contextMenu);
      setContextMenuLocation({ x: event.pageX, y: event.pageY });
    };
  };

  return <>
    <div className='flex flex-wrap' onClick={clearRightClick}>
      {folders.map((folder) => (
        <Icon key={folder.name} isSelected={selectedIcon === folder.name} onClick={() => setSelectedIcon(folder.name)} onDoubleClick={() => setCurrentPath(`${props.path === '/' ? '' : props.path}/${folder.name}`)} onRightClick={rightClickHandler(ShownContextMenu.Folder, `${props.path === '/' ? '' : props.path}/${folder.name}`)}>
          <IconIcon>
            <i className='bi bi-folder'></i>
          </IconIcon>
          <IconLabel>
            {folder.name}
          </IconLabel>
        </Icon>
      ))}
      {files.map((file) => (
        <Icon key={file.name} isSelected={selectedIcon === file.id} onClick={() => setSelectedIcon(file.id)} onDoubleClick={openFile(file.id)} onRightClick={rightClickHandler(ShownContextMenu.File, file.id)}>
          <IconIcon>
            <i className={`bi bi-file-earmark bi-filetype-${getFileExtension(file.name)}`}></i>
          </IconIcon>
          <IconLabel>
            <span title={file.name} className='inline-block text-ellipsis overflow-hidden select-none w-24'>{file.name}</span>
          </IconLabel>
        </Icon>
      ))}
    </div>
    {shownContextMenu === ShownContextMenu.Folder && contextMenuLocation != null && selectedIcon != null && <FolderContextMenu closeMenu={clearRightClick} position={contextMenuLocation} id={selectedIcon} />}
    {shownContextMenu === ShownContextMenu.File && contextMenuLocation != null && selectedIcon != null && <FileContextMenu closeMenu={clearRightClick} position={contextMenuLocation} id={selectedIcon} />}
  </>;
};

export default IconView;
