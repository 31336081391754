import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFilesApi } from '../../api';
import { useAPI } from '../../libs/libAPI';
import useOutsideClickHandler from '../../libs/useOutsideClickHandler';
import ContextMenuProps from './ContextMenuProps';

const FileContextMenu: React.FC<ContextMenuProps> = (props) => {
  const api = useAPI();
  const filesApi = useFilesApi();
  const [search, setSearch] = useSearchParams();
  const ref = useRef<any>();

  useOutsideClickHandler(props.closeMenu, ref);

  if (props.position == null) return <></>;

  const openProperties = (): void => {
    setSearch((prev) => {
      prev.set('fileProperties', props.id);
      return prev;
    });
    props.closeMenu();
  };

  const openFolder = (): void => {
    setSearch((prev) => {
      const currentPath = prev.get('path') ?? '/';
      prev.set('path', `${currentPath === '/' ? '' : currentPath}/${props.id}`);
      return prev;
    });
  };
  const copyUrl = (): void => {
    void (async () => {
      const urlResponse = await filesApi.get_file_content_url({
        params: {
          file_id: props.id
        }
      })
      await navigator.clipboard.writeText(urlResponse.url);
    })();
  };

  const download = (): void => {
    void (async () => {
      const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/files/${props.id}/content-url?download=true`, {
        method: 'GET'
      });
      const data = await response.json();
      console.log(data);
      window.open(data.url, '_blank');
    })();
  };

  const style: React.CSSProperties = {};

  if ((props.position.x + 200) > window.innerWidth) {
    style.right = window.innerWidth - props.position.x;
  } else {
    style.left = props.position.x;
  }

  if ((props.position.y + 500) > window.innerHeight) {
    style.bottom = window.innerHeight - props.position.y;
  } else {
    style.top = props.position.y;
  }

  return <>
    <div ref={ref} style={style} className='absolute bg-background p-2 rounded-lg border border-background2-darker drop-shadow-lg select-none'>
      <div className='px-2 py-1 rounded-lg hover:bg-background-dark hover:cursor-pointer' onClick={openFolder}>Open</div>
      <div className='px-2 py-1 rounded-lg hover:bg-background-dark hover:cursor-pointer' onClick={download}>Download</div>
      <div className='px-2 py-1 rounded-lg hover:bg-background-dark hover:cursor-pointer'>Version(s)</div>
      <hr />
      <div className='px-2 py-1 rounded-lg hover:bg-background-dark hover:cursor-pointer'>Rename</div>
      <div className='px-2 py-1 rounded-lg hover:bg-background-dark hover:cursor-pointer' onClick={copyUrl}>Copy Url</div>
      <div className='px-2 py-1 rounded-lg hover:bg-background-dark hover:cursor-pointer' onClick={openProperties}>Properties</div>
    </div>
  </>;
};

export default FileContextMenu;
