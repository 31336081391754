import React, { useEffect, useMemo, useState } from 'react';

import { File, Folder as ApiFolder } from '../../api';
import useFilesQuery from './useFilesQuery';

interface FolderProps {
  path: string;
  displayName: string;
  expandedByDefault?: boolean;
  currentPath: string;
  setCurrentPath: (path: string) => void;
}

const Folder: React.FC<FolderProps> = (props) => {
  const [expanded, setExpanded] = useState(props.expandedByDefault ?? false);
  const { isLoading, isError, data } = useFilesQuery(props.path);

  useEffect(() => {
    if (props.currentPath.startsWith(props.path)) {
      setExpanded(true);
    }
  }, [props.currentPath, props.path]);

  const folders = useMemo(() => {
    if (data == null) return [];

    const list = data.filter(i => (i as File | ApiFolder).type === 'folder');

    return list as ApiFolder[];
  }, [data]);

  if (isLoading) {
    return <li><span>Loading...</span></li>;
  }

  if (isError) {
    return <span>Error:</span>;
  }

  const setCurrentPathHere = (): void => {
    setExpanded(true);
    props.setCurrentPath(props.path);
  };

  const toggleExpanded = (): void => {
    setExpanded(!expanded);
  };

  const isCurrentPath = props.currentPath === props.path;

  if (folders.length === 0) {
    return <>
      <li>
        <span className='text-background'><i className={`bi bi-caret-${expanded ? 'up' : 'down'}`}></i></span>
        <span className={`cursor-pointer ${isCurrentPath ? 'border border-dotted' : ''}`} onClick={setCurrentPathHere}><i className="bi bi-folder"></i> {props.displayName}</span>
      </li>
    </>;
  }

  return <>
    <li>
      <span className='cursor-pointer' onClick={toggleExpanded}><i className={`bi bi-${expanded ? 'dash' : 'plus'}`}></i></span>
      <span className={`cursor-pointer ${isCurrentPath ? 'border border-dotted' : ''}`} onClick={setCurrentPathHere}><i className='bi bi-folder'></i> {props.displayName}</span>
      {expanded && <ul className='pl-2'>
        {folders.map((folder) => (
          <Folder key={folder.name} currentPath={props.currentPath} setCurrentPath={props.setCurrentPath} displayName={folder.name} path={`${props.path === '/' ? '' : props.path}/${folder.name}`} />
        ))}
      </ul>}
    </li>
  </>;
};

export default Folder;
