import React from 'react';
import { Outlet } from 'react-router-dom';

import Frame from '../../../components/Library/Frame';
import useTitle from '../../../libs/useTitle';
import SettingsApiCredentialsList from './ApiCredentialsList';

const SettingsApiCredentials: React.FC = () => {
  useTitle('API Credentials');

  return <>
    <div className='flex'>
      <Frame varient='primary' title='API Credentials' className='w-200 mx-auto'>
        <SettingsApiCredentialsList />
      </Frame>
    </div>

    <Outlet />
  </>;
};

export default SettingsApiCredentials;
