import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import useOutsideClickHandler from '../../libs/useOutsideClickHandler';
import ContextMenuProps from './ContextMenuProps';

const FolderContextMenu: React.FC<ContextMenuProps> = (props) => {
  const [search, setSearch] = useSearchParams();
  const ref = useRef<any>();

  useOutsideClickHandler(props.closeMenu, ref);

  if (props.position == null) return <></>;

  const openProperties = (): void => {
    setSearch((prev) => {
      prev.set('folderProperties', props.id);
      return prev;
    });
    props.closeMenu();
  };

  const openFolder = (): void => {
    setSearch((prev) => {
      const currentPath = prev.get('path') ?? '/';
      prev.set('path', `${currentPath === '/' ? '' : currentPath}/${props.id}`);
      return prev;
    });
  };

  const style: React.CSSProperties = {};

  if (ref.current != null) {
    const menuDiv = ref.current as HTMLDivElement;
    if ((props.position.x + menuDiv.clientWidth) > window.innerWidth) {
      style.right = window.innerWidth - props.position.x;
    } else {
      style.left = props.position.x;
    }

    if ((props.position.y + menuDiv.clientHeight) > window.innerHeight) {
      style.bottom = window.innerHeight - props.position.y;
    } else {
      style.top = props.position.y;
    }
  } else {
    style.left = props.position.x;
    style.top = props.position.y;
  }

  return <>
    <div ref={ref} style={style} className='absolute bg-background p-2 rounded-lg border border-background2-darker drop-shadow-lg select-none'>
      <div className='px-2 py-1 rounded-lg hover:bg-background-dark hover:cursor-pointer' onClick={openFolder}>Open</div>
      <hr className='bg-background2-darker' />
      <div className='px-2 py-1 rounded-lg hover:bg-background-dark hover:cursor-pointer' onClick={openProperties}>Properties</div>
    </div>
  </>;
};

export default FolderContextMenu;
