import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from '../../../components/Library/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../components/Library/Modal';
import InlineLoadingSpinner from '../../../components/LoadingSpinner/InlineLoadingSpinner';
import { useAccountContext } from '../../../contexts/AccountContext';
import { useAPI } from '../../../libs/libAPI';
import WDApiCredential from '../../../types/WDApiCredential';

const SettingsApiCredentialsDelete: React.FC = () => {
  const api = useAPI();
  const accountContext = useAccountContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const context: WDApiCredential | undefined = useOutletContext();

  const deleteMutation = useMutation(async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/api-credentials/${context?.clientId ?? 'null'}`, { method: 'DELETE' });

    if (response.ok) {
      return;
    }
    throw Error(await response.text());
  }, {
    onSuccess: () => {
      navigate('../..');
      void queryClient.resetQueries({ queryKey: ['settings', 'api-credentials', accountContext.accountId] });
    }
  });

  const deleteCredential = (): void => {
    deleteMutation.mutate();
  };

  const closeModal = (): void => {
    navigate('..');
  };

  return <>
    <Modal show closeModal={closeModal}>
      <ModalHeader closeModal={closeModal}>Delete: {context?.name}</ModalHeader>
      <ModalBody>
        {deleteMutation.isLoading
          ? <InlineLoadingSpinner />
          : <>Do you really want to delete &quot;{context?.name}&quot;?</>}
      </ModalBody>
      {!deleteMutation.isLoading && <ModalFooter>
        <Button variant='secondary' onClick={closeModal}>No</Button>
        <Button variant='danger' onClick={deleteCredential}>Yes</Button>
      </ModalFooter>}
    </Modal>
  </>;
};

export default SettingsApiCredentialsDelete;
