import { z } from 'zod';

import { WDApiCredentialSchema } from './WDApiCredential';

export const WDApiCredentialNewSchema = z.intersection(WDApiCredentialSchema, z.object({
  clientSecret: z.string()
}));

type WDApiCredentialNew = z.infer<typeof WDApiCredentialNewSchema>;

export default WDApiCredentialNew;
