import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import {
  createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable
} from '@tanstack/react-table';

import CenterLoadingSpinner from '../../../components/LoadingSpinner/CenterLoadingSpinner';
import { useAccountContext } from '../../../contexts/AccountContext';
import { useAPI } from '../../../libs/libAPI';
import useApiQuery from '../../../libs/useApiQuery';
import { sortBy } from '../../../libs/utils';
import WDApiCredential, { WDApiCredentialSchema } from '../../../types/WDApiCredential';

const columnHelper = createColumnHelper<WDApiCredential>();

const columns = [
  columnHelper.accessor('name', {
    header: () => 'Name',
    cell: info => <Link to={info.row.original.clientId} className='underline'>{info.getValue()}</Link>
  }),
  columnHelper.accessor('lastActivity', {
    header: () => 'Last Activity',
    cell: info => info.getValue() ?? <i>null</i>
  })
];

const SettingsApiCredentialsList: React.FC = () => {
  const accountContext = useAccountContext();

  const data = useApiQuery<WDApiCredential[]>(['settings', 'api-credentials', accountContext.accountId], `${import.meta.env.VITE_API_URI}/v1/api-credentials`, WDApiCredentialSchema.array());

  const table = useReactTable({
    data: data.data?.sort(sortBy('name')) ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  if (data.data == null) {
    return <CenterLoadingSpinner />;
  }

  return <>
    <table className='w-full p-3'>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className='border-b border-b-background3-dark'>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className='py-3 text-left px-2'>
                {header.isPlaceholder
                  ? null
                  : <div>
                {flexRender(header.column.columnDef.header, header.getContext())}
                </div>}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id} className='border-b border-b-background3-dark hover:bg-hover'>
            {row.getVisibleCells().map(cell => {
              return (
                <td key={cell.id} className='p-2 whitespace-nowrap overflow-ellipsis'>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
    <div className='mt-4 mb-2'>
      <Link to='new' className='ml-1 mt-3 px-2 py-2 rounded-lg text-success-text bg-success hover:bg-success-dark shadow-md shadow-hover'>New</Link>
    </div>
  </>;
};

export default SettingsApiCredentialsList;
