import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Button from '../../../components/Library/Button';
import Input from '../../../components/Library/Input';
import Label from '../../../components/Library/Label';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../components/Library/Modal';
import InlineLoadingSpinner from '../../../components/LoadingSpinner/InlineLoadingSpinner';
import { UseApi, useAPI } from '../../../libs/libAPI';
import useApiQuery from '../../../libs/useApiQuery';
import { toClipboard } from '../../../libs/utils';
import WDApiCredential, { WDApiCredentialSchema } from '../../../types/WDApiCredential';
import WDApiCredentialNew, { WDApiCredentialNewSchema } from '../../../types/WDApiCredentialNew';

interface SettingsApiCredentialsDetailsBodyProps {
  credential: WDApiCredential | WDApiCredentialNew;
  regenerateSecret: () => void;
  editMode: boolean;
  registerSave: (saveCallback: () => void) => void;
  unregisterSave: () => void;
}

const regenerateSecret = (credentialId: string, api: UseApi, setUpdatedCredential: (credential: WDApiCredentialNew) => void) => {
  return () => {
    const doAsync = async (): Promise<void> => {
      const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/api-credentials/${credentialId ?? 'null'}/regenerate-secret`, { method: 'POST' });

      const credential: WDApiCredentialNew = await WDApiCredentialNewSchema.parseAsync(await response.json());

      setUpdatedCredential(credential);
    };
    void doAsync();
  };
};

const SettingsApiCredentialsDetailsBody: React.FC<SettingsApiCredentialsDetailsBodyProps> = (props) => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const [name, setName] = useState(props.credential.name);

  const save = (): void => {
    const doAsync = async (): Promise<void> => {
      const data = {
        name
      };

      const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/api-credentials/${props.credential.clientId}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const credential: WDApiCredential = await WDApiCredentialSchema.parseAsync(await response.json());

      queryClient.setQueryData(['settings', 'api-credential', props.credential.clientId], credential);
    };
    void doAsync();
  };

  useEffect(() => {
    props.registerSave(save);
    return () => {
      props.unregisterSave();
    };
  }, []);

  useEffect(() => {
    if (!props.editMode) {
      setName(props.credential.name);
    }
  }, [props.editMode]);

  return <>
    <div>
      <div>
        <Label htmlFor='name'>Name</Label>
        <Input id='name' readOnly={!props.editMode} value={name} onChange={(e) => setName(e.target.value)} className='w-full' />
      </div>

      <div>
        <Label htmlFor='clientId'>Client Id</Label>
        <div className='w-full'>
          <Input id='clientId' readOnly value={props.credential.clientId} className='inline-block w-[calc(100%-40px)]' />
          <Button variant='secondary' onClick={toClipboard(props.credential.clientId)}><i className="bi bi-clipboard"></i></Button>
        </div>
      </div>

      <div>
        <Label htmlFor='clientSecret'>Client Secret</Label>
        <div className='w-full'>
          {((props.credential as WDApiCredentialNew).clientSecret != null)
            ? <Input id='clientSecret' type='text' readOnly value={(props.credential as WDApiCredentialNew).clientSecret} className='inline-block w-[calc(100%-80px)]' />
            : <Input id='clientSecret' type='password' readOnly value={'This is a fake secret. Do not use.'} className='inline-block w-[calc(100%-80px)]' />}
          <Button variant='danger' onClick={props.regenerateSecret}><i className="bi bi-recycle"></i></Button>
          <Button variant='secondary' onClick={toClipboard((props.credential as WDApiCredentialNew).clientSecret)} disabled={((props.credential as WDApiCredentialNew).clientSecret == null)}><i className="bi bi-clipboard"></i></Button>
        </div>
      </div>
    </div>
  </>;
};

export const SettingsApiCredentialsDetailsNew: React.FC = () => {
  const api = useAPI();
  const navigate = useNavigate();

  const [newCredential, setNewCredential] = useState<WDApiCredentialNew | null>(null);

  const [name, setName] = useState('');

  const newMutation = useMutation(async () => {
    const data = {
      name
    };

    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/api-credentials`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const credential: WDApiCredentialNew = await WDApiCredentialNewSchema.parseAsync(await response.json());

    return credential;
  }, {
    onSuccess: (data) => {
      setNewCredential(data);
    }
  });

  const saveNew = (): void => {
    newMutation.mutate();
  };

  const closeModal = (): void => {
    navigate('..');
  };

  return <>
    <Modal show={true} modalSize='Normal' closeModal={closeModal}>
      <ModalHeader closeModal={closeModal}>{newCredential?.name ?? 'New Api Credential'}</ModalHeader>
      <ModalBody>
        {newMutation.isLoading
          ? <>
            <InlineLoadingSpinner />
          </>
          : <>
            {newCredential == null
              ? <>
              <div>
                <Label htmlFor='name'>Name</Label>
                <Input id='name' readOnly={newCredential != null} value={name} onChange={(e) => setName(e.target.value)} className='w-full' />
              </div>
            </>
              : <>
              <SettingsApiCredentialsDetailsBody credential={newCredential} editMode={false} regenerateSecret={regenerateSecret(newCredential?.clientId ?? 'null', api, setNewCredential)} registerSave={() => {}} unregisterSave={() => {}} />
            </>}
        </>}
      </ModalBody>
      {(newCredential == null || newMutation.isLoading) && <ModalFooter>
        <Button variant='primary' type='submit' onClick={saveNew}>Save</Button>
          <Button variant='danger' type='reset' onClick={closeModal}>Cancel</Button>
      </ModalFooter>}
    </Modal>
  </>;
};

const SettingsApiCredentialsDetails: React.FC = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const { credentialId } = useParams();

  const [editMode, setEditMode] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const [saveCallback, setSaveCallback] = useState<null | (() => void)>(null);
  const [updatedCredential, setUpdatedCredential] = useState<WDApiCredentialNew | null>(null);

  const data = useApiQuery<WDApiCredential>(['settings', 'api-credential', credentialId ?? 'null'], `${import.meta.env.VITE_API_URI}/v1/api-credentials/${credentialId ?? 'null'}`, WDApiCredentialSchema);

  const closeModal = (): void => {
    navigate('..');
  };

  const registerSave = (callback: () => void): void => {
    setSaveCallback(callback);
  };

  const unregisterSave = (): void => {
    setSaveCallback(null);
  };

  const save = (): void => {
    setUpdatedCredential(null);
    if (saveCallback != null) {
      saveCallback();
    }
  };

  const optionButton = <>
    <button className='box-content ml-auto mr-2 w-4 h-4 text-nav-txt border-none rounded-none focus:shadow-none focus:outline-none hover:text-nav-text-dark hover:opacity-75 hover:no-underline' onClick={() => setEditMode(true)}><i className='bi bi-pencil-square'></i></button>
  </>;

  return <>
    <Modal show={true} modalSize='Normal' closeModal={closeModal}>
      <ModalHeader closeModal={closeModal} optionButtons={editMode ? undefined : optionButton}>{data.data?.name ?? 'Loading...'}</ModalHeader>
      <ModalBody>
        {data.data == null ? <InlineLoadingSpinner /> : <SettingsApiCredentialsDetailsBody editMode={editMode} credential={updatedCredential ?? data.data} regenerateSecret={regenerateSecret(credentialId ?? 'null', api, setUpdatedCredential)} registerSave={registerSave} unregisterSave={unregisterSave} />}
      </ModalBody>
      <ModalFooter>
        {editMode
          ? <>
        <Button variant='primary' type='submit' onClick={save}>Save</Button>
        <Button variant='secondary' type='reset' onClick={() => setEditMode(false)}>Cancel</Button>
        </>
          : <>
          {data.data != null && <Link to='delete' className='mx-auto px-2 py-1 rounded-lg text-danger-text bg-danger hover:bg-danger-dark shadow-md shadow-hover'>Delete</Link>}
        </>}
      </ModalFooter>
    </Modal>
    <Outlet context={data.data} />
  </>;
};

export default SettingsApiCredentialsDetails;
