import { z } from 'zod';

export const WDFileStatsSchema = z.object({
  archiveFileCount: z.number().int(),
  liveFileCount: z.number().int(),
  totalFileCount: z.number().int(),
  totalSize: z.number().int(),
  totalUsed: z.number().int(),
  usedArchive: z.number().int(),
  usedLive: z.number().int()
});

type WDFileStats = z.infer<typeof WDFileStatsSchema>;

export default WDFileStats;
