import React from 'react';

import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { FileBrowserViewMode } from '../../types/FileBrowserViewMode';
import FolderPath from './FolderPath';
import IconView from './IconView';
import ListView from './ListView';
import useFilesQuery from './useFilesQuery';
import { ViewModeProps } from './ViewModeProps';

interface FileBrowserProps {
  path: string;
  setCurrentPath: (path: string) => void;
  viewMode: FileBrowserViewMode;
}

type ViewsType = {
  [mode in FileBrowserViewMode]: React.FC<ViewModeProps>;
};

const Views: ViewsType = {
  icon: IconView,
  list: ListView
};

const FileBrowser: React.FC<FileBrowserProps> = (props) => {
  const { isLoading, isError, data } = useFilesQuery(props.path);

  if (isLoading) {
    return <CenterLoadingSpinner />;
  }

  if (isError) {
    return <></>;
    // TODO
  }

  const View = Views[props.viewMode];

  return <div>
    <div className='w-full h-10 flex bg-background2'>
      <FolderPath path={props.path} />
    </div>
    <View data={data} path={props.path} setCurrentPath={props.setCurrentPath} />
  </div>;
};

export default FileBrowser;
